// Update Inter font to the latest version. Google Fonts is not always up to date.
@import url('https://cdnjs.cloudflare.com/ajax/libs/inter-ui/4.0.2/inter.min.css');

// Ensure Blueprint popovers render above all other elements
.bp5-portal:has(.bp5-popover-transition-container .bp5-popover) {
  z-index: 50;
}

// Lighten the placeholder color
.bp5-input.bp5-input::-moz-placeholder {
  color: #c8c8c8;
}
.bp5-input.bp5-input:-ms-input-placeholder {
  color: #c8c8c8;
}
.bp5-input.bp5-input::placeholder {
  color: #c8c8c8;
}

